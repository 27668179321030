import * as React from 'react'
import { withPreviewResolver } from 'gatsby-source-prismic'
import Layout from '../components/Layout'
import styled from '@emotion/styled'
import linkResolver from '../utils/linkResolverPreview'

const Loading = styled.div`
  color: #000;
  font-size: 4rem;
  padding: 14.4rem 0;
  text-align: center;
`

const PreviewPage = ({ isPreview }) => {
  if (isPreview === false) return (
    <Layout>
      <Loading>Naughty naughty! You're not supposed to be here.</Loading>
    </Layout>
  )

  return (
    <Layout>
      <Loading>Loading your preview.</Loading>
    </Layout>
  )
}

export default withPreviewResolver(PreviewPage, {
  repositoryName: 'leading-edge',
  accessToken: 'MC5Yak5Oc1JBQUFDTUFlbWFC.Ge-_ve-_vTI277-9Ku-_vRodaHzvv70lCe-_vQZB77-9PBAo77-9QO-_vS9tLTTvv73vv73vv70',
  linkResolver: () => (doc) => linkResolver(doc),
})
