const linkResolver = (doc) => {
  if (doc.type === 'about_us') return '/about-us/';
  if (doc.type === 'blog') return '/blog/';
  if (doc.type === 'category') return `/blog/${doc.uid}/`;
  if (doc.type === 'collections') return '/collections/';
  if (doc.type === 'contact_page') return '/contact-us/';
  if (doc.type === 'donate') return `/donate/`;
  if (doc.type === 'faqs') return '/faqs/';
  if (doc.type === 'person') return `/bio/${doc.uid}/`;
  if (doc.type === 'post') return `/post/${doc.uid}/`;
  if (doc.type === 'programs') return '/programs/';
  if (doc.type === 'resource') return `/resource/${doc.uid}/`;
  if (doc.type === 'resources') return `/resources/`;
  if (doc.type === 'simple_page') return `/page/${doc.uid}`;
  if (doc.type === 'team') return `/team/`;
  if (doc.type === 'topic') return `/topic/${doc.uid}`;
  if (doc.type === 'program_arm') return `/program-area/${doc.uid}`;
  if (doc.type === 'program') return `/program/${doc.uid}`;

  return `/`;
};

module.exports = linkResolver